<template>
	<div class="inner">
		<div v-if="compareList.length == 0" style="min-height: 79vh;">
			<el-empty :image-size="200" description="暂无任何对比车型信息"></el-empty>
		</div>
		<div v-else class="container">
			<div class="all-title over mt50 pt40 pb16 el-border-bottom mb20">
				<div class="fl ft36 bold">车型对比</div>
				<div class="fl ft24 bl9 ml12 mt12"> / Models contrast</div>
			</div>
			<div class="pt20 l16 scroll-x">
				<table class="tc ft14" cellpadding="0" cellspacing="0">
					<tr>
						<td class="left-box">汽车名称</td>
						<td v-for="item in compareList" :key="item.id">
							<div class="flex-bottom">
								<span>
									{{item.carInfo.name}}
								</span>
								<span class="ft30 hover">
									<i class="el-icon-circle-close" @click="handleCompareCar(item.carCompare.id)"></i>
								</span>
							</div>
						</td>
					</tr>
					<tr class="tl">
						<td class="left-box">厂商指导价（元）</td>
						<td v-for="item in compareList" :key="item.id">
							<span v-if="item.carInfo && item.carInfo.price > 0">{{item.carInfo.price}}万</span>
							<span v-else>暂无报价</span>
						</td>
					</tr>
					<tr class="tl">
						<td class="left-box">车型</td>
						<td v-for="item in compareList" :key="item.id">{{item.carInfo.code}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">车款</td>
						<td v-for="item in compareList" :key="item.id">{{item.carInfo.carStyle}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">排量</td>
						<td v-for="item in compareList" :key="item.id">{{item.carInfo.displacementCode}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">功率</td>
						<td v-for="item in compareList" :key="item.id">{{item.carInfo.power}}</td>
					</tr>
					<!--<tr class="tl">-->
						<!--<td class="left-box">国别</td>-->
						<!--<td v-for="item in compareList" :key="item.id">{{item.carInfo.power}}</td>-->
					<!--</tr>-->


					<tr class="title">
						<td colspan="5" style="background-color: #FFFFFF;text-align: center;" class="left-box tc red ft20 pt20 pb20">基本参数</td>
					</tr>
					<tr class="tl">
						<td class="left-box">产品尺寸</td>
						<td v-for="item in compareList" :key="item.id">{{item.carBasicInfo ? item.carBasicInfo.carSize : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">油箱容量</td>
						<td v-for="item in compareList" :key="item.id">{{item.carBasicInfo ? item.carBasicInfo.tankCapacity : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">扭矩</td>
						<td v-for="item in compareList" :key="item.id">{{item.carBasicInfo ? item.carBasicInfo.torque : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">发动机形式</td>
						<td v-for="item in compareList" :key="item.id">{{item.carBasicInfo ? item.carBasicInfo.fuelCategory : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">燃油类别</td>
						<td v-for="item in compareList" :key="item.id">{{item.carBasicInfo ? item.carBasicInfo.engineType : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">变速器</td>
						<td v-for="item in compareList" :key="item.id">{{item.carBasicInfo ? item.carBasicInfo.transmission : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">四驱</td>
						<td v-for="item in compareList" :key="item.id">{{item.carBasicInfo ? item.carBasicInfo.fourWheel : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">差速</td>
						<td v-for="item in compareList" :key="item.id">{{item.carBasicInfo ? item.carBasicInfo.diffSpeed : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">刹车系统</td>
						<td v-for="item in compareList" :key="item.id">{{item.carBasicInfo ? item.carBasicInfo.brakeSystem : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">悬挂系统</td>
						<td v-for="item in compareList" :key="item.id">{{item.carBasicInfo ? item.carBasicInfo.suspensionSystem : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">轮毂及尺寸</td>
						<td v-for="item in compareList" :key="item.id">{{item.carBasicInfo ? item.carBasicInfo.tireSize : ""}}</td>
					</tr>

					<tr class="title">
						<td colspan="5" style="background-color: #FFFFFF;text-align: center;" class="left-box tc red ft20 pt20 pb20">配置参数</td>
					</tr>
					<tr class="tl">
						<td class="left-box">选装概叙</td>
						<td v-for="item in compareList" :key="item.id">{{item.carAppearance ? item.carAppearance.wheelSize : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">保险杠及包围</td>
						<td v-for="item in compareList" :key="item.id">{{item.carAppearance ? item.carAppearance.surround : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">泥挡</td>
						<td v-for="item in compareList" :key="item.id">{{item.carAppearance ? item.carAppearance.mudguard == 0 ? '无' : '有' : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">玻璃</td>
						<td v-for="item in compareList" :key="item.id">{{item.carAppearance ? item.carAppearance.glass : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">天窗</td>
						<td v-for="item in compareList" :key="item.id">{{item.carAppearance ? item.carAppearance.skylight : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">刹车灯</td>
						<td v-for="item in compareList" :key="item.id">{{item.carAppearance ? item.carAppearance.stoplight == 0 ? '无' : '有' : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">侧镜功能</td>
						<td v-for="item in compareList" :key="item.id">{{item.carAppearance ? item.carAppearance.sideMirror : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">门把手</td>
						<td v-for="item in compareList" :key="item.id">{{item.carAppearance ? item.carAppearance.doorHandle : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">脚踏</td>
						<td v-for="item in compareList" :key="item.id">{{item.carAppearance ? item.carAppearance.pedal : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">防撞杠</td>
						<td v-for="item in compareList" :key="item.id">{{item.carAppearance ? item.carAppearance.bumper == 0 ? '无' : '有' : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">大灯形式</td>
						<td v-for="item in compareList" :key="item.id">{{item.carAppearance ? item.carAppearance.xenonHeadlamp : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">转向灯</td>
						<td v-for="item in compareList" :key="item.id">{{item.carAppearance ? item.carAppearance.corneringLamp == 0 ? '无' : '有' : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">前雾灯</td>
						<td v-for="item in compareList" :key="item.id">{{item.carAppearance ? item.carAppearance.frontFogLamp == 0 ? '无' : '有' : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">备胎</td>
						<td v-for="item in compareList" :key="item.id">{{item.carAppearance ? item.carAppearance.spareTyre : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">汽车中网</td>
						<td v-for="item in compareList" :key="item.id">{{item.carAppearance ? item.carAppearance.carGrid : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">行李架</td>
						<td v-for="item in compareList" :key="item.id">{{item.carAppearance ? item.carAppearance.luggageRack == 0 ? '无' : '有' : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">日间行车灯</td>
						<td v-for="item in compareList" :key="item.id">{{item.carAppearance ? item.carAppearance.runLights == 0 ? '无' : '有' : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">头灯清洗</td>
						<td v-for="item in compareList" :key="item.id">{{item.carAppearance ? item.carAppearance.headlampCleaning == 0 ? '无' : '有' : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">保护条</td>
						<td v-for="item in compareList" :key="item.id">{{item.carAppearance ? item.carAppearance.protectionStrip == 0 ? '无' : '有' : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">彩条</td>
						<td v-for="item in compareList" :key="item.id">{{item.carAppearance ? item.carAppearance.colorBar == 0 ? '无' : '有' : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">备胎形式</td>
						<td v-for="item in compareList" :key="item.id">{{item.carAppearance ? item.carAppearance.spareTyreLogo : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">拖钩</td>
						<td v-for="item in compareList" :key="item.id">{{item.carAppearance ? item.carAppearance.towHook : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">安全性概括</td>
						<td v-for="item in compareList" :key="item.id">{{item.carAppearance ? item.carSafety.safetyDesc : ""}}</td>
					</tr>


					<tr class="tl">
						<td class="left-box">气囊前座/窗帘/前侧/司机膝盖</td>
						<td v-for="item in compareList" :key="item.id">{{item.carSafety ? item.carSafety.gasBag : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">电眼</td>
						<td v-for="item in compareList" :key="item.id">{{item.carSafety ? item.carSafety.electricEye : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">防碰撞安全系统</td>
						<td v-for="item in compareList" :key="item.id">{{item.carSafety ? item.carSafety.antiCollision == 0 ? '无' : '有' : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">KDSS</td>
						<td v-for="item in compareList" :key="item.id">{{item.carSafety ? item.carSafety.kdss == 0 ? '无' : '有' : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">电调记忆方向盘</td>
						<td v-for="item in compareList" :key="item.id">{{item.carSafety ? item.carVipassana.steeringWheel == 0 ? '无' : '有' : ""}}</td>
					</tr>



					<tr class="tl">
						<td class="left-box">中央手扶箱</td>
						<td v-for="item in compareList" :key="item.id">{{item.carVipassana ? item.carVipassana.jewelryBox == 0 ? '无' : '有' : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">桃木装饰</td>
						<td v-for="item in compareList" :key="item.id">{{item.carVipassana ? item.carVipassana.decorate : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">真皮桃木方向盘+桃木档杆</td>
						<td v-for="item in compareList" :key="item.id">{{item.carVipassana ? item.carVipassana.corium : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">油箱盖开启器</td>
						<td v-for="item in compareList" :key="item.id">{{item.carVipassana ? item.carVipassana.opener == 0 ? '无' : '有' : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">座椅材质+座位数</td>
						<td v-for="item in compareList" :key="item.id">{{item.carVipassana ? item.carVipassana.seatNum : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">前排电座</td>
						<td v-for="item in compareList" :key="item.id">{{item.carVipassana ? item.carVipassana.frontSeat : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">司机记忆座</td>
						<td v-for="item in compareList" :key="item.id">{{item.carVipassana ? item.carVipassana.memorySeat == 0 ? '无' : '有' : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">司机座腰部承托/垂直调节</td>
						<td v-for="item in compareList" :key="item.id">{{item.carVipassana ? item.carVipassana.verticalAdjust == 0 ? '无' : '有' : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">车内后视镜</td>
						<td v-for="item in compareList" :key="item.id">{{item.carVipassana ? item.carVipassana.rearviewMirror : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">化妆镜及照明灯</td>
						<td v-for="item in compareList" :key="item.id">{{item.carVipassana ? item.carVipassana.vanityMirror == 0 ? '无' : '有' : ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">组合仪表</td>
						<td v-for="item in compareList" :key="item.id">{{item.carVipassana ? item.carVipassana.ipc : ""}}</td>
					</tr>


					<tr class="tl">
						<td class="left-box">前后雨刷</td>
						<td v-for="item in compareList" :key="item.id">{{item.carEquipment ? item.carEquipment.rearWiper == 0 ? '无' : '有': ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">电窗</td>
						<td v-for="item in compareList" :key="item.id">{{item.carEquipment ? item.carEquipment.electricWindow: ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">电吸门</td>
						<td v-for="item in compareList" :key="item.id">{{item.carEquipment ? item.carEquipment.electricDoor: ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">电动侧滑门</td>
						<td v-for="item in compareList" :key="item.id">{{item.carEquipment ? item.carEquipment.electricAutoDoor: ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">中央门锁</td>
						<td v-for="item in compareList" :key="item.id">{{item.carEquipment ? item.carEquipment.centralLock == 0 ? '无' : '有': ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">无钥匙进入+一键式启动</td>
						<td v-for="item in compareList" :key="item.id">{{item.carEquipment ? item.carEquipment.touchStart == 0 ? '无' : '有': ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">后挡风加热</td>
						<td v-for="item in compareList" :key="item.id">{{item.carEquipment ? item.carEquipment.weifaHotline == 0 ? '无' : '有': ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">迎宾灯</td>
						<td v-for="item in compareList" :key="item.id">{{item.carEquipment ? item.carEquipment.welcomeLamp == 0 ? '无' : '有': ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">收音机,AUX,USB,蓝牙,9喇叭</td>
						<td v-for="item in compareList" :key="item.id">{{item.carEquipment ? item.carEquipment.radioSetting: ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">空调形式</td>
						<td v-for="item in compareList" :key="item.id">{{item.carEquipment ? item.carEquipment.automaticAir: ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">雷测（自适应巡航）</td>
						<td v-for="item in compareList" :key="item.id">{{item.carEquipment ? item.carEquipment.cruiseControl == 0 ? '无' : '有': ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">冰箱</td>
						<td v-for="item in compareList" :key="item.id">{{item.carEquipment ? item.carEquipment.iceBox == 0 ? '无' : '有': ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">遥控启动</td>
						<td v-for="item in compareList" :key="item.id">{{item.carEquipment ? item.carEquipment.remoteStart == 0 ? '无' : '有': ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">摄像头</td>
						<td v-for="item in compareList" :key="item.id">{{item.carEquipment ? item.carEquipment.camera: ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">导航</td>
						<td v-for="item in compareList" :key="item.id">{{item.carEquipment ? item.carEquipment.navSite == 0 ? '无' : '有': ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">倒影</td>
						<td v-for="item in compareList" :key="item.id">{{item.carEquipment ? item.carEquipment.inverted == 0 ? '无' : '有': ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">后排娱乐系统</td>
						<td v-for="item in compareList" :key="item.id">{{item.carEquipment ? item.carEquipment.entertainment: ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">低速巡航</td>
						<td v-for="item in compareList" :key="item.id">{{item.carEquipment ? item.carEquipment.speedCruise == 0 ? '无' : '有': ""}}</td>
					</tr>
					<tr class="tl">
						<td class="left-box">特色配置</td>
						<td v-for="item in compareList" :key="item.id">{{item.carEquipment ? item.carEquipment.characteristic: ""}}</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
	import { saveCompareCar, carCompareList, getCompareCode, delCompareCar} from '@/api/index.js'
	export default {
		data() {
			return {
				compareList:[]
			}
		},
		created(){
			if(this.$route.query.code == 0){
				this.getCompareList(localStorage.getItem('compareCode'))
			}else{
				this.getGenrateCode(this.$route.query.code)
			}
		},
		methods: {
			getGenrateCode(code){
				if(localStorage.getItem('compareCode') && localStorage.getItem('compareCode') != undefined && localStorage.getItem('compareCode') != 'undefined'){
					this.getSaveCompare(code)
				}else{
					getCompareCode().then(res=>{
						if(res.code === 200){
							localStorage.setItem('compareCode', res.data)
							this.getSaveCompare(code)
						}
					})
				}
			},
			/**
			 * 保存对比车型信息
			 */
			getSaveCompare(code){
				let data = {
					infoCode: code,
					compareCode:localStorage.getItem('compareCode')
				}
				saveCompareCar(data).then(res=>{
					if(res.code === 200){
						// 查询对比列表信息
						this.getCompareList(localStorage.getItem('compareCode'))
					}
				})
			},

			/**
			 * 获取对比车型信息
			 */
			getCompareList(compareCode){
				carCompareList(compareCode).then(res=>{
					if(res.code === 200){
						this.compareList = res.data;
					}
				})
			},

			handleCompareCar(id){
				delCompareCar(id).then(res=>{
					if(res.code === 200){
						this.getCompareList(localStorage.getItem('compareCode'))
					}
				})
			}

		}
	}
</script>

<style scoped="scoped">
.scroll-x{overflow: hidden;overflow-x: auto;margin-bottom: 100px;}
.scroll-x table{min-width: 100%;width: max-content;border-bottom: 1px solid #f1f1f1;}
.scroll-x table .left-box{position: sticky;left:0;z-index: 2;background-color:#F0D3D5;width:140px;
padding: 30px;text-align: right;}
.scroll-x table tr td{border-top: 1px solid #f1f1f1;padding: 30px 20px;box-sizing: border-box;width: 250px;border-right: 1px solid #f1f1f1;}

.flex-bottom{position: relative;}
.flex-bottom .hover{position: absolute;bottom: 0px;right: 10px;line-height: 1;}
</style>
